<template>
    <div>
        <div class="elePaySuccess">
            <Header backType="blank">
                <span slot="headText">支付成功</span>
            </Header>
            <div>
                <Svgs name="iconsuccess1" color="#4AC672" classes="icon-success"></Svgs>
                <div class="elePaySuccessTitle">支付成功</div>
                <div class="elePaySuccessMount">￥{{parseFloat(mealItemDetail.settleAmount || orderDetail.orderAmount).toFixed(2)}}</div>
                <div class="elePaySuccessContent">
                    <div class="card">
                        <div>商户信息</div>
                        <div>{{mealItemDetail.shopName}}</div>
                    </div>
                    <div class="card">
                        <div>订单号</div>
                        <div>{{orderDetail.extOrderId || mealItemDetail.extOrderId}}</div>
                    </div>
                </div>
                <div class="elePaySuccessTxt">
                    <span>*</span>
                    如遇支付问题,请致电
                    <span @click="ihtml.telCall('01056304858')" v-html="phoneText"></span>
                </div>
                
            </div>
        </div>
        <div class="elePaySuccessBtnGroup">
            <Btn btnClass="elePaySuccessBtn elePaySuccessDis" @callBack="backPath">
                <span slot="btnContent">关闭</span>
            </Btn>
            <Btn btnClass="elePaySuccessBtn" @callBack="goInvoice">
                <span slot="btnContent">上传发票</span>
            </Btn>
        </div>
    </div>
</template>
<script>
import { mapGetters,mapMutations } from "vuex";
import { miceService } from "@/service/miceService.js";
import { phoneList } from '@/config/phone/index.js'
import { indicator } from 'smart-indicator'
export default {
    data() {
        return {
            mealItemDetail: {}
        }
    },
    computed: {
        ...mapGetters([
            "orderDetail",
            "event"
        ]),
        phoneText: {
            get: function() {
                let tenant = this.event.tenant_code || 'pfizer';
                return phoneList[tenant];  
            }    
        }
    },
    created() {
        indicator.open()
        let _this = this
        const params= {
            itemId: this.orderDetail.itemId
        }
        setTimeout(() => {
            indicator.close()
            miceService.getMealItemDetail(params).then(res => {
                if(res && res.success) {
                    _this.mealItemDetail = res.content
                    _this['ORDER_DETAIL']({realAmount: _this.mealItemDetail.settleAmount, ..._this.orderDetail})
                }
            })
        }, 2000)
    },
    methods: {
        ...mapMutations(["ORDER_DETAIL"]),
        backPath() {
            this.$router.push({path: '/orderList'})
        },
        goInvoice() {
            this.$router.push({path: '/uploadInvoice'})
        }
    }
};
</script>
<style lang="scss">
@import "ElePayResult.scss";
</style>
